<template>
  <div>
    <v-toolbar tile absolute color="white" short class="toolbar_tick">
      <div>
        <v-toolbar-title><span>Company Brief's Answers</span></v-toolbar-title>
      </div>
    </v-toolbar>
    <v-container fluid style="margin-top: 4rem; background-color: #f3f4f8">
      <v-row justify="end">
        <v-col cols="3">
          <v-text-field
            v-model="briefSearch"
            label="Search"
            single-line
            hide-details
            @keydown.enter.prevent="searchBrief()"
            :disabled="disSearch"
          >
            <template v-slot:append>
              <v-btn icon @click="searchBrief()">
                <v-icon color="#00a5ff"> mdi-card-search </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-row justify="space-between">
        <v-col cols="12">
          <div class="mb-5">
            <template v-if="isLoading">
              <div class="text-center ma-12">
                <v-progress-circular
                  :indeterminate="true"
                  :size="100"
                  :width="5"
                  color="light-blue"
                >
                </v-progress-circular>
              </div>
            </template>
            <template>
              <v-data-table
                v-if="!isLoading"
                :headers="headers"
                :items="lstBrief"
                :search="briefSearch"
              >
              <template slot="item.id" slot-scope="{ item }"> #{{ item.id }} </template>
              <template slot="item.name" slot-scope="{ item }">
                  {{ item.clientName }}
                </template>
                <template slot="item.date" slot-scope="{ item }">
                  {{ formatDate(item.created_at) }}
                </template>
                <template slot="item.email" slot-scope="{ item }">
                  {{ item.brief.email }}
                </template>
                <template slot="item.website" slot-scope="{ item }">
                  {{ item.brief.website }}
                </template>
                <template slot="item.viewItem" slot-scope="{ item }">
                  <v-btn icon color="#00a5ff" @click="viewDetails(item)">
                    <v-icon> mdi-eye </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <brief-data
      v-if="openModal"
      :brief="briefSelected"
      :dialog="openModal"
      @closeDialog="openModal = false"
    />
  </div>
</template>
<script>
import { getBriefs } from "./../services/service";
import { BriefData } from "@advise/styleguide";
export default {
  components: { BriefData },
  data() {
    return {
      openModal: false,
      briefSelected: {},
      isLoading: false,
      lstTickets: [],
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: true,
          filterable: false,
        },
        {
          text: "Company",
          value: "company",
          sortable: false,
          filterable: true,
        },
        {
          text: "Created",
          value: "date",
          sortable: false,
          filterable: false,
        },
        {
          text: "e-mail",
          value: "email",
          sortable: false,
          filterable: true,
        },
        {
          text: "Web site",
          value: "website",
          sortable: false,
          filterable: true,
        },
        {
          text: "View",
          value: "viewItem",
          sortable: false,
          filterable: false,
        },
      ],
      lstBrief: [],
      briefSearch: "",
      disSearch: false,
    };
  },
  methods: {
    formatDate(date) {
      if (date === null) return "-";
      const fec = new Date(date);
      fec.setDate(fec.getDate());
      return fec.toLocaleString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
    },
    viewDetails(item) {
      this.briefSelected = item;
      this.openModal = true;
    },
    searchBrief() {
      /* console.log("search", this.briefSearch); */
    },
  },
  async created() {
    this.isLoading = true;
    // const clientId = sessionStorage.getItem("client_id");
    const data = await getBriefs();
    this.lstBrief = data.map((el) => {
      return {
        id: el.id,
        brief: JSON.parse(el.brief),
        created_at: el.created_at,
        company: el.company,
      };
    });
    /* console.log(data);
    console.log(this.lstBrief); */
    this.isLoading = false;
  },
};
</script>
<style scoped>
.toolbar_tick {
  padding: 0px !important;
  margin: 0px !important;
  left: 0px;
  width: 100%;
  margin-top: 100px;
}

.toolbar_tick span {
  font-size: 20px;
  font-family: "Oxygen", sans-serif;
  font-weight: 900;
  color: #2c3852;
  padding-left: 1rem;
}
</style>