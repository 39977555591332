import axios from 'axios'
axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(
      'token',
    )}|${sessionStorage.getItem('refresh')}|${sessionStorage.getItem(
      'ip_public',
    )}|${sessionStorage.getItem('ip_local')}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)
// const adviseApi = process.env.VUE_APP_ADVISE_API_LOCAL
const adviseApi = process.env.VUE_APP_ADVISE_API

export const getBriefs = async () => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/brief/brief/back-office`,
    )
    return data
  } catch (error) {
    return false
  }
}